.experience {
    /*height: calc(100vh - 150px);*/
    width: 100vw;
    /*top: 30%;*/
    /*left: 20%;*/
    /*margin-top: 150px;*/
    margin-bottom: 50px;
    /*top: 40%;*/
    /*position: relative;*/
    background-color: white;
    overflow: hidden;
}

.experience-skills {
    width: 30%;
    position: fixed;
    top: 200px;
    left: 60%;
}

.experience-skills h3 {
    font-size: 2vw;
}
.experience-skills div {
    font-size: 1.3vw;
}

#header-experience {
    margin: 0;
    position: relative;
    /*margin-top: 150px;*/
    font-weight: bold;
    font-size: 2rem;
    color: black;
    width: max-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

#header-experience-sideways {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    transform: rotate(-90deg) translateY(-100px) translateX(-350px);
    font-size: 2rem;
    color: black;
    width: max-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

#header-wrapper-experience {
    /*position: relative;*/
    /*top: 20%;*/
    margin-top: 150px;
    margin-left: 150px;
    width: fit-content;
    margin-bottom: 40px;
}

#divider-experience {
    /*width: 60vw;*/
    height: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
}

.header {
    padding-left: 30px;
    color: black;
    font-family: "Plus Jakarta Sans", sans-serif
}

.header-2 {
    color: black;
    padding: 30px 30px 30px;
}

@media screen and (max-width: 1024px) {
    .experience-skills {
        display: none;
    }
    #header-experience-sideways {
        display: none;
    }
    #divider-experience {
        width: 85vw;
        height: 4px;
    }
    #header-wrapper-experience {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
    }
    #header-experience {
        font-size: 28px;
        margin: auto;
    }
}