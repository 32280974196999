@keyframes progress-fade-in {
    from {
        opacity: 0;
        filter: blur(10px);
    }

    to {
        opacity: 1;
        filter: blur(0px);
    }
}

@keyframes progress-fade-out {

    from {
        opacity: 1;
        filter: blur(0px);
    }

    to {
        opacity: 0;
        filter: blur(10px);
    }
}

.show-circular-progress {
    animation: progress-fade-in 1s forwards;
}

.hide-circular-progress {
    animation: progress-fade-out 1s forwards;
}

.init-circular-progress-class {
    opacity: 0;
}

#navigation {
    display: block;
}
#navigation-mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    #navigation {
        display: none;
    }
    #navigation-mobile {
        display: block;
    }
}