.project-item-image {
    object-fit: contain;
    width: 100%;
    /*border-top-left-radius: 8px;*/
    /*border-top-right-radius: 8px;*/
}

.project-item-wrapper {
    width: 50vw;
    box-shadow: grey;
    margin: auto;

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

@media screen and (max-width: 970px) {
    .project-item-wrapper {
        width: 90vw;
    }
}

.project-item-title {
    color: black;
    font-family: "Plus Jakarta Sans", sans-serif;
    /*font-size: 24px;*/
    padding: 0 30px;
    font-weight: bold;
}

.project-item-link {
    color: dimgrey;
    font-size: 14px;
    padding: 0 30px 5px;
    font-family: "Plus Jakarta Sans", sans-serif;
}

.project-content-wrapper {
    /*padding: 16px;*/
}

.project-item-description {
    color: black;
    /*font-size: 0.875rem;*/
    line-height: 1.3;
    background: #f1f1f1;
    padding: 30px 30px;
    margin: 20px 0;
    font-family: "Plus Jakarta Sans", sans-serif;
}

.responsive-iframe {
    width: 100%;
    height: 500px;
}

.project-actions {
    /*float: right;*/
    /*padding-left: 16px;*/
    /*padding-bottom: 20px;*/
    padding: 0 30px 20px;
    /*height: 30px;*/
    width: max-content;
}

@media screen and (max-width: 768px) {
    .responsive-iframe {
        height: 200px;
    }
    .project-item-title {
        font-size: 20px;
    }
    .project-item-description {
        font-size: 14px;
    }
}