.timeline {
    position: relative;
    /*width: 85%;*/
    left: 150px;
}

@media screen and (max-width: 1024px) {
    .timeline {
        left: 0;
        margin: auto;
        width: fit-content;
    }
}