@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&family=Roboto&display=swap');

#canvas-wrapper {
    /*filter: blur(4px);*/
    height: 100vh;
    width: 100vw;
    background: white;
}

#canvas-basic {
    border: 0;
    opacity: 100%;
    filter: brightness(70%);
    /*transform: scale(1.1, 1);*/
    height: 100vh;
}

.darken-canvas {
    opacity: 50%;
}

#header-wrapper {
    position: fixed;
    top: 25%;
    left: 150px;
    width: 55vw;
}

#icons-wrapper {
    width: calc(3*82px);
    /*margin: auto;*/
    /*position: absolute;*/
    /*bottom: 10%;*/
    position: relative;
    /*top: 30%;*/
    margin-top: 5%;
    /*left: 0;*/
    right: 8px;
}

#header {
    margin: 0;
    position: relative;
    font-size: 4rem;
    color: black;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

#divider {
    width: 60vw;
    height: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: black;
}

#sub-header {
    margin: 5% 0 0;
    font-size: 2vw;
    width: 80%;
    color: black;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

#lottie {
    position: fixed;
    top: 20vh;
    left: 60vw;
}
@media screen and (max-width: 1024px) {
    #header {
        font-size: 38px;
        /*text-align: justify;*/
    }
    #header-wrapper {
        top: 250px;
    }
    #lottie {
        /*display: none;*/
        top: 100px;
        left: 50%;
        height: 100px;
        width: 100px;
        transform: translateX(-50%) !important;
    }

    #sub-header {
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
        width: 55%;
    }
    #divider {
        height: 20px;
        opacity: 0.4;
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 2em);
    }
    #header-wrapper {
        width: 90vw;
        left: 5vw;
        text-align: center;
    }
    #icons-wrapper {
        top: 10vh;
        left: 7px;
        width: fit-content;
        /*margin-bottom: 20vh;*/
        height: 100vh;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 768px) {
    #header {
        font-size: 34px;
        /*text-align: justify;*/
    }
    #sub-header {
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        width: 85%;
    }
}

/*@media screen and (max-width: 400px) {*/
/*    #header {*/
/*        font-size: 30px;*/
/*    }*/

/*    #sub-header {*/
/*        font-size: 26px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 350px) {*/
/*    #header {*/
/*        font-size: 26px;*/
/*    }*/

/*    #sub-header {*/
/*        font-size: 22px;*/
/*    }*/
/*}*/
