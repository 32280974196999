.timeline-item {
    /*display: flex;*/
    /*flex-direction: row;*/
    width: 45%;
    /*width: fit-content;*/
    /*padding-bottom: 40px;*/
    margin-bottom: 20px;
}
.timeline-item-skills {
    width: 40%;
}
.timeline-item-wrapper {
    /*width: 40vw;*/
    z-index: 1;
}

.date-wrapper {
    width: calc(20vw - 60px);
    /*width: 100%;*/
    position: relative;
    padding-left: 40px;
    left: 20px;
    z-index: 0;
    /*height: 100%;*/
    font-family: "Plus Jakarta Sans", sans-serif;
    border: 1px white dashed;
}


.timeline-item-header {
    width: 100%;
    /*padding-left: 30px;*/
    padding-top: 10px;
    /*padding-bottom: 5px;*/
    /*height: inherit;*/
    background-color: white;
}

.header {
    padding-left: 30px;
    color: black;
    font-family: "Plus Jakarta Sans", sans-serif
}

.header-2 {
    color: black;
    padding: 30px 30px 30px;
}

.date-range {
    color: white;
    position: relative;
    /*left: 100px;*/
}

@media screen and (max-width: 1024px) {
    .timeline-item {
        flex-direction: column-reverse;
        width: 100%;
    }
    .timeline-item-wrapper {
        width: 90vw;
    }
    .timeline-item-header {

    }
    .date-wrapper {
        padding-left: 30px;
        left: 0;
        width: calc(80vw - 2px);
    }
    #title-header {
        font-size: 20px;
    }
    #company-header {
        font-size: 16px;
    }
    #description-text {
        font-size: 14px;
    }
    .date-wrapper {
        font-size: 12px;
    }
    .timeline-options {
        font-size: 12px;
    }

}



.timeline-item-body {
    width: 100%;
    /*height: 200px;*/
    background-color: #f1f1f1;
}

.timeline-options {
    padding: 0 30px 20px;
    align-items: center;
    display: flex;
    /*padding-bottom: 20px;*/
    justify-content:space-between;
}
.timeline-date-wrapper {
    font-size: 20px;
    position: absolute;
    transform: rotate(-90deg) translateX(-50%) translateY(-700%);
}
