#about-me {
    /*background-image: url("https://firebasestorage.googleapis.com/v0/b/personal-site-57ec2.appspot.com/o/images%2Ffancy-pants.jpg?alt=media&token=ba8dff08-eda7-4965-a01c-7ae6a36f2348");*/
    /*height: 100vh;*/
    height: 80vh;
    margin-top: 20vh;
    /*height: 100vh;*/
}

#header-aboutme {
    margin: 0;
    width: max-content;
    /*position: relative;*/
    font-size: 2rem;
    color: black;
    font-family: 'Plus Jakarta Sans', sans-serif;
    backdrop-filter: blur(4px);
    font-weight: bold;
}

.about-me-overlay {
    position: absolute;
    height: 100vh;
    width: 100vw;
    filter: blur(4px);
    z-index: 1;
}

#header-wrapper-aboutme {
    position: relative;
    /*top: 20vh;*/
    top: 60px;
    left: 150px;
    width: 85%;
    /*margin-bottom: 40px;*/
}

.about-me-card {
    /*position: relative;*/
    /*top: 30%;*/
    /*margin-top: 30vh;*/
    width: fit-content;
    max-width: 700px;
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    /*height: 80vh;*/

}

.mac-dialog {

}

.about-me-text {
    /*position: relative;*/
    margin-left: 30px;
    width: 80%;
    height: max-content;

    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 18px;
    backdrop-filter: blur(8px);
    padding: 5px 20px;
    margin-top: auto;
    margin-bottom: auto;
}

.about-me-img {
    width: auto;
    max-width: 300px;
}

.about-me-card-content {
    display: flex;
    flex-direction: row;
}

.mac-buttons {

}

.mac-button {
    width: max-content;
}

/*.about-me-header {*/
/*    color: black;*/
/*    font-family: 'Plus Jakarta Sans', sans-serif;*/
/*    font-size: 16px;*/
/*    height: max-content;*/
/*    padding-top: 5px;*/
/*    position: relative;*/
/*    left: calc(15% + 20px + 0.5rem);*/
/*}*/


@media screen and (max-width: 768px) {
    .about-me-text {
        font-size: 14px;
        padding-left: 0px;
        padding-right: 0px;
        word-break: break-word;
        width: 90%;
        margin: auto;
    }
    #header-aboutme {
        font-size: 28px;
        margin: auto;
        width: fit-content;
    }
    .about-me-card {
        width: 90vw;
        margin: auto;
        margin-bottom: 20px;
    }
    #about-me {
        margin-top: 10vh;
        height: 90vh;
    }
    .about-me-img {
        height: auto;
        width: auto;
        margin: auto;
    }
    #header-wrapper-aboutme {
        width: 85vw;
        margin: 40px auto 20px;
        top: 0;
        left: 0;
        /*left: calc(15vw / 2);*/
    }


}