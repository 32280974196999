#projects {
    /*width: 100vw;*/
    height: 100%;
    /*background-color: white;*/
    /*background-repeat: repeat;*/
    padding-bottom: 80px;
    background-size: initial;
    /*opacity: 0;*/
    /*filter: blur(20px);*/
    /*backdrop-filter: brightness(0%);*/
    /*overflow: hidden;*/
}

/* project animations */
@keyframes show-project {
    from {
        opacity: 0;
        filter: blur(20px);
    }
    to {
        opacity: 1;
        filter: blur(0px) brightness(100%);

    }
}

.projects-init {
    opacity: 0;
    filter: blur(20px);
}

.projects-show {
    animation: show-project 1s forwards;
}

.projects-hide {
    opacity: 0;
    filter: blur(20px);
}

.projects-mobile {
    opacity: 1;
    filter: blur(0px);
}

#header-projects {
    margin: 0;
    position: relative;
    /*margin-top: 150px;*/
    font-weight: bold;
    font-size: 2rem;
    color: black;
    width: max-content;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

#header-wrapper-projects {
    position: relative;
    top: 20vh;
    /*width: 60vw;*/
    margin-bottom: 40px;
    /*margin-right: 20%;*/
    padding-bottom: 40px;
    /*backdrop-filter: blur(5px);*/
    /*background-color: white;*/
    /*padding: 10px;*/
}

#divider-projects {
    width: 60vw;
    position: relative;
    top: 10px;
    height: 5px;
    /*border: 2px dashed black;*/
    /*margin-top: 10px;*/
    /*margin-bottom: 10px;*/
    background-color: black;
}

#projects-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 200px;
    /*left: 33vw;*/
}

#projects-wrapper-row {
    flex-direction: column;
}

/* Project List */
@keyframes select-project {
    from {
        transform: scale(0.8);
        filter: blur(4px);
    }
    to {
        transform: scale(1);
        filter: blur(0);
    }
}

@keyframes unselect-project {
    from {
        transform: scale(1);
        filter: blur(0);
    }
    to {
        transform: scale(0.8);
        filter: blur(4px);
    }
}

.selected {
    animation: select-project 1s forwards;
}

.unselected {
    animation: unselect-project 1s forwards;
}

.projects-wrapper {
    margin-bottom: 40px;
}
#header-mobile {
    display: none;
}
#header-desktop {
    display: block;
}

@media screen and (max-width: 768px) {
    #header-wrapper-projects {
        /*width: 80vw;*/
        width: fit-content;
        left: 0;
        top: unset;
        margin: auto;
    }
    #divider-projects {
        width: 85vw;
        height: 4px;
    }
    #header-projects {
        font-size: 28px;
    }
    #projects-wrapper {
        margin-top: 0;
    }
    #projects {
        padding-bottom: 0;
    }
    #header-mobile {
        display: block;
    }
    #header-desktop {
        display: none;
    }
}